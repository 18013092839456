<template>
  <section class="container">
    <div class="row justify-content-center justify-content-md-between mb-3 mr-0 ml-2 ml-sm-0">
      <mdb-btn
        flat
        dark-waves
        icon="arrow-left"
        type="button"
        title="Regresar"
        @click="$router.go(-1)"
      >
        Regresar
      </mdb-btn>
    </div>
    <div class="encabezado-detalles">
      <header class="h4-responsive text-center pt-3">Reporte</header>
      <div class="row mx-0 mt-2">
        <div class="col-12 col-sm-5">
          <span class="small-block font-titulo">Nombre del reporte</span>
          <p>
            Pendientes vs Despachado
          </p>
        </div>
        <div class="col-12 col-sm">
          <span class="small-block font-titulo">Mes</span>
          <p>
            {{ fechaResumen }}
          </p>
        </div>
        <div class="col-12">
          <span class="small-block font-titulo">Descripción</span>
          <p class="mb-1">
            Reporte de los Pedidos pendientes hasta la fecha seleccionada vs. lo despachado en el rango de fecha seleccionado.
          </p>
          <p class="mb-1">Muestra la cantidad de producto despachado en el rango de fecha seleccionada.</p>
          <p>Muestra los Pedidos con estatus En cola o Incompletos hasta la fecha seleccionada.</p>
        </div>
        <!-- <div class="col-12 col-sm">
          <span class="small-block font-titulo">Vendedor</span>
          <p :class="{'text-muted font-italic user-select-none': !(vendedorSeleccionado && typeof (vendedorSeleccionado) === 'object'&& vendedorSeleccionado !== null)}">
            {{
              vendedorSeleccionado && typeof (vendedorSeleccionado) === 'object'&& vendedorSeleccionado !== null
                ? `${vendedorSeleccionado.nombre} ${vendedorSeleccionado.apellido}`
                : 'Sin especificar'
            }}
          </p>
        </div> -->
      </div>
    </div>
    <div class="row align-items-center mb-3">
      <div class="col-12 col-sm-12 col-md-4 col-xl-3">
        <mdb-input
          v-model="fechaResumen"
          type="month"
          label="Mes"
          class="my-2"
          outline
        />
      </div>
      <div class="col-12 col-sm col-xl-3">
        <mdb-input
          v-model="fechaDel"
          type="date"
          label="Desde"
          class="my-2"
          outline
        />
      </div>
      <div class="col-12 col-sm col-xl-3">
        <mdb-input
          v-model="fechaHasta"
          type="date"
          label="Hasta"
          class="my-2"
          outline
        />
      </div>
      <div
        v-if="fechaHasta.length && fechaDel.length"
        class="col text-center"
      >
        <mdb-btn
          flat
          dark-waves
          icon="broom"
          class="my-2 px-1"
          @click="restablecerFiltros()"
        >
          Restablecer filtros
        </mdb-btn>
      </div>
    </div>
    <!-- Mensaje cuando el vendedor seleccionado no tiene pedido -->
    <div
      v-if="cargando"
      class="mensaje-no-items py-3"
    >
      <LoaderKel />
      <p class="texto mt-2">
        Cargando
      </p>
    </div>
    <div
      v-else-if="!pedidos.length"
      class="mensaje-no-items pb-3"
    >
      <font-awesome-icon
        icon="clipboard-list"
        size="5x"
        class="icono"
      />
      <p class="texto">No hay despachos en la fecha seleccionada</p>
    </div>
    <ul
      v-else
      class="table despachos"
    >
      <li class="encabezado sticky">
        <div class="row mx-0">
          <div class="col-12 col-sm px-0">
            <header class="h4-responsive">Pendiente vs Despachado</header>
          </div>
          <mdb-btn
            flat
            dark-waves
            icon="print"
            class="col col-sm-3 col-md-2 px-3 py-2 mx-sm-0 mr-md-3 ml-md-2"
            @click="imprimirTablaPendienteVsDespachado(pedidos,totales,fechaResumen)"
          >
            Imprimir
          </mdb-btn>
        </div>
        <div class="thead claro">
          <div class="col-12 col-sm col-lg-4">
            Producto - Descripción
            <span class="small-block">
              Código - Categoría
            </span>
          </div>
          <div class="col-12 col-sm col-md-2 col-lg-3 text-sm-right">
            Pedido pendiente
            <span class="small-block">
              # Kilo
            </span>
          </div>
          <div class="col-12 col-sm col-md-2 col-lg-3 text-sm-right">
            Despachado
            <span class="small-block">
              # Kilo
            </span>
          </div>
          <!-- <div class="col-12 col-sm col-md-2 text-sm-right">
            Faltante
            <span class="small-block">
              actual
            </span>
          </div> -->
        </div>
      </li>
      <li
        class="contenido"
        v-for="p in pedidos"
        :key="p.id_pre"
      >
        <div
          class="col-12 col-sm col-lg-4"
          data-columna="Producto - Descripción / Código - Categoría"
        >
          <p>
            {{p.nom_pro}} - {{p.des_pre}}
            <span class="small-block">
              {{p.cod_pre}} - {{p.nom_cat}}
            </span>
          </p>
        </div>
        <div
          class="col-12 col-sm col-md-2 col-lg-3 celda-numerica"
          data-columna="Pedido / # Kilo"
        >
          <p>
            {{parseFloat(p.cantidadPedida).toLocaleString('es')}}
            <span class="small-block">
              {{parseFloat(p.pesoPedido).toLocaleString('es')}} kg
            </span>
          </p>
        </div>
        <div
          class="col-12 col-sm col-md-2 col-lg-3 celda-numerica"
          data-columna="Despacho / # Kilo"
        >
          <p>
            {{parseFloat(p.cantidadDespachada).toLocaleString('es')}}
            <span class="small-block">
              {{parseFloat(p.pesoDespachado).toLocaleString('es')}} kg
            </span>
          </p>
        </div>
        <!-- <div
          class="col-12 col-sm col-md-2 col-lg-2 celda-numerica"
          data-columna="Pedidos vs Despacho"
        >
          <p>
            {{p.porcentajeCumplimiento}}
          </p>
        </div> -->
      </li>
      <li class="contenido claro">
        <div
          class="col-12 col-sm col-lg-4"
          data-columna="Totales"
        />
        <div
          class="col-12 col-sm col-md-2 col-lg-3 celda-numerica"
          data-columna="Pedido / # Kilo"
        >
          <p>
            {{parseFloat(totales.pedido).toLocaleString('es')}}
            <span class="small-block">
              {{ parseFloat(totales.pesoPedido).toLocaleString('es') }} kg
            </span>
          </p>
        </div>
        <div
          class="col-12 col-sm col-md-2 col-lg-3 celda-numerica"
          data-columna="Despacho / # Kilo"
        >
          <p>
            {{  parseFloat(totales.despachado).toLocaleString('es') }}
            <span class="small-block">{{ parseFloat(totales.pesoDespachado).toLocaleString('es') }} kg</span>
          </p>
        </div>
        <!-- <div
          class="col-12 col-sm col-md-2 col-lg-2 celda-numerica"
          data-columna="Pedidos vs Despacho"
        >
          <p>
            {{isFinite(Number((totales.despachado/totales.pedido*100).toFixed(2)))
              ? Number((totales.despachado/totales.pedido*100).toFixed(2))+' %'
              : ''
            }}
            <span class="small-block">
              {{isFinite(Number((totales.pesoDespachado/totales.pesoPedido*100).toFixed(2)))
                ? Number((totales.pesoDespachado/totales.pesoPedido*100).toFixed(2))+' % (kg)'
                : '0'
              }}
            </span>
          </p>
        </div> -->
      </li>
    </ul>
    <AlertaMensaje
      :alerta-mensaje="alertaMensaje"
      @cerrar="alertaMensaje.contenido = ''"
    />
    <OpcionesImpresion
      :mostrar-modal="opcionesDeImpresion.mostrarModal"
      :codigoDeImpresion="opcionesDeImpresion.codigoDeImpresion"
      :nombre-documento="opcionesDeImpresion.nombreDocumento"
      @cerrar="opcionesDeImpresion.mostrarModal = $event"
    />
  </section>
</template>

<script>
import { mdbInput, mdbBtn } from 'mdbvue'
import AlertaMensaje from '@/components/AlertaMensaje.vue'
import OpcionesImpresion from '@/components/OpcionesImpresion.vue'
import { PendientevsDespachado } from '@/funciones/formatosImpresion.js'
import { apiPost } from '@/funciones/api.js'
import LoaderKel from '@/components/LoaderKel.vue'

export default {
  name: 'PendienteVsDespachado',
  components: {
    mdbInput,
    mdbBtn,
    AlertaMensaje,
    OpcionesImpresion,
    LoaderKel
  },
  props: {
    // mostrarSidebarVendedores: {
    //   type: Boolean,
    //   required: true
    // }
  },
  data () {
    return {
      alertaMensaje: { contenido: '' },
      vendedor: 0,
      fechaResumen: new Date().getFullYear() + '-' + ('0' + (new Date().getMonth() + 1)).substr(-2),
      fechaDel: '',
      fechaHasta: '',
      vendedores: [],
      pedidos: [],
      totales: {
        pedido: 0,
        despachado: 0,
        pesoPedido: 0,
        pesoDespachado: 0
      },
      opcionesDeImpresion: {
        codigoDeImpresion: [],
        mostrarModal: false,
        nombreDocumento: ''
      },
      cargando: false
    }
  },
  mounted () {
    this.actualizar()
  },
  watch: {
    fechaResumen () {
      this.fechaDel = ''
      this.fechaHasta = ''
      this.actualizar()
    },
    fechaHasta () {
      if (!this.fechaHasta) return
      this.actualizar()
    }
  },
  methods: {
    restablecerFiltros () {
      this.fechaDel = ''
      this.fechaHasta = ''
      this.actualizar()
    },
    imprimir () {
      window.print()
    },
    actualizar () {
      this.cargando = true
      let data = this.fechaResumen ? 'y=' + this.fechaResumen.split('-')[0] +
        '&m=' + this.fechaResumen.split('-')[1] + '&' : ''
      data += this.fechaHasta ? 'del=' + this.fechaDel + '&al=' + this.fechaHasta : ''
      apiPost({ s: 'pendientevsdespachadoMv' }, data)
        .then((r) => {
          this.totales = {
            pedido: 0,
            despachado: 0,
            pesoPedido: 0,
            pesoDespachado: 0
          }
          this.pedidos = r.data.ele.map((e) => {
            const cantidadDespachada = Number(e.can_sap)
            const pesoPedido = Number(((e.can_ped || 0) * e.pesm_pre).toFixed(2))
            const pesoDespachado = Number((cantidadDespachada * e.pesm_pre).toFixed(2))
            this.totales.pedido += Number(e.can_ped || 0)
            this.totales.despachado += cantidadDespachada
            this.totales.pesoPedido += Number(pesoPedido)
            this.totales.pesoDespachado += Number(pesoDespachado)
            return {
              nom_pro: e.nom_pro,
              des_pre: e.des_pre,
              cod_pre: e.cod_pre,
              nom_cat: e.nom_cat,
              cantidadPedida: e.can_ped || 0,
              cantidadDespachada,
              pesoPedido,
              pesoDespachado,
              pesm_pre: e.pesm_pre,
              can_ped: e.can_ped || 0
              // porcentajeCumplimiento: isFinite(((e.can_ped) / e.can_sap * 100).toFixed(2)) ? Number(((e.can_ped) / e.can_sap * 100).toFixed(2)) + '%' : '100 %'
            }
          })
          this.cargando = false
        })
    },
    imprimirTablaPendienteVsDespachado (pedidos, totales, fechaResumen) {
      // Estableciendo la estructura para la tabla de pedidos
      const PEDIDOS = [
        // Fila de encabezados
        [
          {
            stack: [
              'PRODUCTO, DESCRIPCIÓN',
              {
                text: 'CÓDIGO, CATEGORÍA',
                fontSize: 8
              }
            ]
          },
          {
            alignment: 'right',
            stack: [
              'PEDIDO',
              {
                text: '#KILO',
                fontSize: 8
              }
            ]
          },
          {
            alignment: 'right',
            stack: [
              'DESPACHADO',
              {
                text: '#KILO',
                fontSize: 8
              }
            ]
          }
          // {
          //   alignment: 'right',
          //   stack: [
          //     '',
          //     {
          //       text: '',
          //       fontSize: 8
          //     }
          //   ]
          // }
        ]
      ]

      for (const pedido in pedidos) {
        PEDIDOS.push([
          {
            stack: [
              `${pedidos[pedido].nom_pro}, ${pedidos[pedido].des_pre}`,
              {
                text: `${pedidos[pedido].cod_pre}, ${pedidos[pedido].nom_cat}`,
                fontSize: 8
              }
            ]
          },
          {
            alignment: 'right',
            stack: [
              pedidos[pedido].cantidadPedida,
              {
                text: `${pedidos[pedido].pesm_pre} KG `,
                fontSize: 8
              }
            ]
          },
          {
            alignment: 'right',
            stack: [
              pedidos[pedido].cantidadDespachada,
              {
                text: `${pedidos[pedido].pesoDespachado} KG `,
                fontSize: 8
              }
            ]
          }
          // {
          //   alignment: 'right',
          //   text: '',
          //   fontSize: 10
          // }
        ])
      }
      PEDIDOS.push([
        {
          text: 'TOTALES',
          bold: true
        },
        {
          alignment: 'right',
          stack: [
            `${totales.pedido}`,
            {
              text: `${Number((totales.pesoPedido).toFixed(2))}`,
              fontSize: 8
            }
          ]
        },
        {
          alignment: 'right',
          stack: [
            `${totales.despachado}`,
            {
              text: `${Number((totales.pesoDespachado).toFixed(2))}`,
              fontSize: 8
            }
          ]
        }
        // {
        //   alignment: 'right',
        //   stack: [
        //     '',
        //     {
        //       text: '',
        //       fontSize: 8
        //     }
        //   ]
        // }
      ])
      const { nombre, formato } = PendientevsDespachado(fechaResumen, PEDIDOS)

      this.opcionesDeImpresion = {
        codigoDeImpresion: formato,
        nombreDocumento: nombre,
        mostrarModal: true
      }
    }
  }
}
</script>

<style lang="scss" scoped>
.table.despachos {
  // Define los breakpoints para el responsive de ul.table
  @include ul-table-responsive-at ("576px");
}
</style>
